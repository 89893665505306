import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Application } from '../../models/Application';
import { CheckJWTResponse, LoginRequest, TokenResponse } from '../../models/AuthModels';
import { User, UserLoginInfo } from '../../models/UserModels';
import { StatusEnum } from '../../models/Utils';
import { checkJWT, login, refreshToken } from '../../services/authentication.service';
import { cookieOrg, cookiePortal, cookieProfil, cookieHome, secondLevelDomain, cookieAppPathName, cookieRedirect, cookieUser } from '../../utils/utilconst';
import { deleteCookie, getCookie, isToken2FA, setCookie } from '../../utils/utilfunctions';
import { RootState } from '../store';

interface AuthInfoState {
  status: string,
  token: string | null,
  domain: string | undefined,
  isValidToken: boolean,
  expire: number,
  error: string | null,
  userLoginInfo: Partial<UserLoginInfo> | null
}

const initialState: AuthInfoState = {
  status: StatusEnum.Succeeded,
  token: getCookie(cookiePortal),
  domain: secondLevelDomain,
  isValidToken: false,
  expire: 60 * 60 * 1000,
  error: null,
  userLoginInfo: null
}

export const doLogin = createAsyncThunk('authInfo/doLogin', async (loginreq: LoginRequest) => {
  const response = await login(loginreq);
  const respData = response.data as TokenResponse;
  return respData;
})

export const fetchRefreshToken = createAsyncThunk('authInfo/refreshToken', async () => {
  const response = await refreshToken();
  const respData = response.data as TokenResponse;
  return respData.token;
})

export const checkJsonWebToken = createAsyncThunk('authInfo/checkJsonWebToken', async (jwt: string) => {
  const response = await checkJWT(jwt);
  const respData = response.data as CheckJWTResponse;
  return { isValid: respData.isValid, token: jwt };
})

export const authInfoSlice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
    logoutAction: state => {
      deleteCookie(cookiePortal, state.domain);
      state.status = StatusEnum.Succeeded;
      state.isValidToken = false;
      state.token = null;
      state.error = null;
    },
    autoLoginAction: (state, { payload }: PayloadAction<string>) => {
      state.status = StatusEnum.Succeeded;
      state.token = payload;
      state.error = null;
    },
    goToApplication: (state, { payload }: PayloadAction<{ url: string | undefined, idOrg: string, idProfil: string[], appPathName: string, utente: User, applicazione: Application }>) => {
      setCookie(cookieOrg, payload.idOrg, state.domain);
      setCookie(cookieProfil, JSON.stringify(payload.idProfil), state.domain);
      setCookie(cookieHome, window.location.host, state.domain);
      setCookie(cookieAppPathName, payload.appPathName, state.domain);
      deleteCookie(cookieRedirect, secondLevelDomain);

      const utente = payload?.utente;
      const applicazione = payload?.applicazione;

      setCookie(
        cookieUser,
        JSON.stringify({
          idUtente: utente.idUtente,
          nome: utente?.nome,
          cognome: utente?.cognome,
          nomeUtente: utente?.nomeUtente,
          organizzazioneDescrizione: applicazione?.organizzazioneNome,
          applicazioneSoftwareDescrizione: applicazione?.descrizione,
          profiloDescrizione: applicazione?.descrizioneProfilo
        }),
        state.domain
      )

      payload.url && (window.location.href = payload.url);
    },
    setTokenManually: (state, { payload }: PayloadAction<string>) => {
      state.token = payload;
      setCookie(cookiePortal, payload, state.domain, state.expire);
    }
  },
  extraReducers: builder => {
    // doLogin
    builder.addCase(doLogin.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(doLogin.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      if (!isToken2FA(state.token))
        state.token = null;
      state.isValidToken = false;
      state.userLoginInfo = null;
      deleteCookie(cookiePortal, state.domain);
    })
    builder.addCase(doLogin.fulfilled, (state, { payload }: PayloadAction<TokenResponse>) => {
      state.status = StatusEnum.Succeeded;
      state.token = payload?.token;
      if (isToken2FA(payload?.token)) {
        state.userLoginInfo = {
          nomeUtente: payload.nomeUtente,
          emailAlternativo: payload.emailAlternativo,
          cellulare: payload.cellulare
        };
      } else {
        state.isValidToken = true;
        setCookie(cookiePortal, payload?.token, state.domain, state.expire);
        setCookie(cookieRedirect, window.location.host, secondLevelDomain)  // allows redirection when authenticated using spid
      }
    })

    // fetchRefreshToken
    builder.addCase(fetchRefreshToken.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchRefreshToken.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      state.token = null;
      deleteCookie(cookiePortal, state.domain);
    })
    builder.addCase(fetchRefreshToken.fulfilled, (state, { payload }: PayloadAction<string>) => {
      setCookie(cookiePortal, payload, state.domain, state.expire);
      state.status = StatusEnum.Succeeded;
      state.token = payload;
    })

    // checkJsonWebToken
    builder.addCase(checkJsonWebToken.pending, (state) => {
      state.status = StatusEnum.Loading;
      state.token = null;
      state.isValidToken = false;
    })
    builder.addCase(checkJsonWebToken.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      state.token = null;
      state.isValidToken = false;
      deleteCookie(cookiePortal, state.domain);
    })
    builder.addCase(checkJsonWebToken.fulfilled, (state, { payload }: PayloadAction<{ isValid: boolean, token: string }>) => {
      if (payload) {
        state.status = StatusEnum.Succeeded;
        state.token = payload.isValid ? payload.token : null;
        state.isValidToken = payload.isValid;
      } else {
        state.status = StatusEnum.Failed;
        state.token = null;
        state.isValidToken = false;
        deleteCookie(cookiePortal, state.domain);
      }
    })
  }
})

export const { logoutAction, autoLoginAction, goToApplication, setTokenManually } = authInfoSlice.actions

export const isLoadingAuth = (state: RootState) => state.authInfo.status === StatusEnum.Loading
export const isLogged = (state: RootState) => state.authInfo.token !== null && !isToken2FA(state.authInfo.token);

export default authInfoSlice.reducer